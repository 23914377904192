import { NavLink, Outlet } from "react-router-dom";
import SiteWaves from "./Waves";
import { useState } from "react";
import safi_logo from "../assets/images/safi_logo.png";
import { BasicInformationProvider } from "../Context/BasicInformationContext";
import { AllDataProvider } from "../Context/AllDataContext";

function SiteMaster() {
  const [isShow, setIsShow] = useState(false);

  function toggleNavBar() {
    setIsShow((prevState) => !prevState);
  }

  return (
    <BasicInformationProvider>
      <AllDataProvider>
        <div className="min-h-screen h-screen w-full overflow-hidden bg-black relative">
          {/* <!-- navbar --> */}
          <div className="navbar bg-black h-20 fixed top-0 z-50 w-full flex lg:justify-evenly justify-between">
            <div className="h-full flex items-center">
              <NavLink
                to="/"
                onClick={() => setIsShow(false)}
                className="text-white bg-slate-400 font-RubikDirt text-5xl select-none ml-5"
              >
                <img
                  src={safi_logo}
                  className="w-auto h-14"
                  alt="safi logo"
                ></img>
              </NavLink>
            </div>

            <div
              className="w-14 cursor-pointer h-full lg:hidden flex justify-center items-center text-white"
              id="navbar_button_toggle"
              onClick={toggleNavBar}
            >
              {!isShow ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-10 h-10"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-10 h-10"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </div>

            <nav
              id="navbar_header"
              className={
                isShow
                  ? "fixed top-20 right-0 bg-zinc-900 lg:bg-transparent h-full w-3/4 items-center px-10 transition-all duration-300 md:w-1/3 lg:static lg:flex lg:w-fit"
                  : "fixed top-20 -right-full bg-zinc-900 lg:bg-transparent h-full w-3/4 items-center px-10 transition-all duration-300 md:w-1/3 lg:static lg:flex lg:w-fit"
              }
            >
              <ul className="flex list-none flex-col lg:flex-row lg:items-center text-white font-semibold">
                <NavLink
                  to="/"
                  onClick={() => setIsShow(false)}
                  className={({ isActive }) =>
                    isActive
                      ? "cursor-pointer p-5 text-red-500 duration-300"
                      : "cursor-pointer p-5 hover:text-red-500 duration-300"
                  }
                >
                  Home
                </NavLink>
                <NavLink
                  to="/about"
                  onClick={() => setIsShow(false)}
                  className={({ isActive }) =>
                    isActive
                      ? "cursor-pointer p-5 text-red-500 duration-300"
                      : "cursor-pointer p-5 hover:text-red-500 duration-300"
                  }
                >
                  About
                </NavLink>
                <NavLink
                  to="/portfolio"
                  onClick={() => setIsShow(false)}
                  className={({ isActive }) =>
                    isActive
                      ? "cursor-pointer p-5 text-red-500 duration-300"
                      : "cursor-pointer p-5 hover:text-red-500 duration-300"
                  }
                >
                  Portfolio
                </NavLink>
                <NavLink
                  to="/news"
                  onClick={() => setIsShow(false)}
                  className={({ isActive }) =>
                    isActive
                      ? "cursor-pointer p-5 text-red-500 duration-300"
                      : "cursor-pointer p-5 hover:text-red-500 duration-300"
                  }
                >
                  News
                </NavLink>
                <NavLink
                  to="/contact"
                  onClick={() => setIsShow(false)}
                  className={({ isActive }) =>
                    isActive
                      ? "cursor-pointer p-5 text-red-500 duration-300"
                      : "cursor-pointer p-5 hover:text-red-500 duration-300"
                  }
                >
                  Contact
                </NavLink>
              </ul>
            </nav>
          </div>
          {/* <!-- end navbar --> */}

          {/* <!-- main content --> */}

          <Outlet />
          {/* <!-- end main content --> */}

          {/* <!-- waves --> */}
          <div className="absolute inset-0 w-full h-full pointer-events-none -z-0">
            <div className="particles absolute bottom-0 left-0 right-0 top-20">
              <SiteWaves />
            </div>
          </div>
          {/* <!-- end waves --> */}
        </div>
      </AllDataProvider>
    </BasicInformationProvider>
  );
}

export default SiteMaster;
