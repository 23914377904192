import { useContext, useState } from "react";
import { AllDataContext } from "../../Context/AllDataContext";
import { motion } from "framer-motion";

// import Swiper core and required modules
import { Autoplay, FreeMode } from "swiper";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import { environment } from "../../environment";
import { Modal, Tabs } from "flowbite-react";

function SitePortfolio() {
  const { portfolio } = useContext(AllDataContext);
  const [isShow, setIsShow] = useState(false);
  const [portfolioModalData, setPortfolioModalData] = useState();

  const toggleModal = () => {
    setIsShow((prevVal) => !prevVal);
  };

  const toggleModalwithData = (item) => {
    setIsShow((prevVal) => !prevVal);
    setPortfolioModalData(item);
  };

  return (
    <>
      <motion.main
        initial={{ y: "5%", opacity: 0 }}
        animate={{ y: "0", opacity: 1 }}
        transition={{ duration: 0.75, ease: "easeOut" }}
        exit={{ opacity: 1 }}
        className="w-full h-5/6 pt-20 pb-10 no-scrollbar overflow-y-auto relative z-10"
      >
        {/* <!-- content --> */}
        <section className="w-full h-full">
          <div className="lg:px-72 px-5 text-white pt-10">
            <h1 className="font-bold text-3xl">
              Creative <span className="text-red-500">Portfolio</span>
            </h1>
            <div className="border-dashed border-b w-full border-zinc-800 mt-5"></div>
            <div className="mt-5">
              {/* <!-- Swiper --> */}
              <Swiper
                spaceBetween={30}
                slidesPerView={3}
                freeMode={true}
                modules={[FreeMode, Autoplay]}
                className="mySwiper  h-72"
                breakpoints={{
                  // when window width is >= 320px
                  200: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  // when window width is >= 640px
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },

                  1280: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: true,
                }}
              >
                {portfolio.map((item) => {
                  return (
                    <SwiperSlide
                      key={item.id}
                      onClick={(event) => toggleModalwithData(item)}
                    >
                      <div className="swiper-slide h-72 cursor-pointer">
                        <div className="w-full h-full relative group overflow-hidden">
                          <div className="w-full h-full">
                            <img
                              src={environment.storage + item.main_image}
                              className="object-fill w-full h-full group-hover:scale-110 duration-300"
                              alt={item.slug}
                            />
                          </div>
                          <div className="absolute group-hover:bottom-0 w-full -bottom-full duration-300 flex items-end">
                            <div className="w-5/6 h-20 bg-gray-50 text-black flex flex-col justify-center items-center">
                              <h1 className="uppercase font-bold">
                                {item.name_of_project}
                              </h1>
                              <p className="text-zinc-500">website</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </section>
        {/* <!-- end content --> */}
      </motion.main>
      {/* <!-- Large Modal --> */}

      <Modal
        dismissible="true"
        show={isShow}
        size="4xl"
        position="center"
        className=""
        onClose={toggleModal}
      >
        <Modal.Body className="bg-zinc-800 ">
          <div className="relative w-full h-full max-w-4xl ">
            {/* <!-- Modal content --> */}
            <div className="relative">
              <div className="absolute lg:-right-20 right-0 top-0 z-50">
                <button
                  type="button"
                  onClick={toggleModal}
                  className="text-gray-400 border bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="large-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* <!-- Modal body --> */}
              <div className=" space-y-6 relative text-white ">
                <Tabs.Group aria-label="Tabs with icons" style="underline">
                  <Tabs.Item title="images">
                    <div className="md:h-96 md:no-scrollbar md:overflow-y-auto">
                      {portfolioModalData?.images.map((image) => {
                        return (
                          <div>
                            <img
                              className="w-full h-auto"
                              src={environment.storage + image}
                              alt=""
                            ></img>
                            <div className="border-dashed border-b w-full border-gray-500 my-5"></div>
                          </div>
                        );
                      })}
                    </div>
                  </Tabs.Item>
                  <Tabs.Item active={true} title="description">
                    <div className="space-y-3 md:h-96 md:no-scrollbar md:overflow-y-auto ">
                      <div className="flex items-center">
                        <div className=" text-red-500">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-4 h-4"
                          >
                            <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
                          </svg>
                        </div>
                        <div className="">
                          <span className="text-gray-500 mx-2">
                            Name of Project :
                          </span>
                          {portfolioModalData?.name_of_project}
                        </div>
                      </div>

                      <div className="flex items-center">
                        <div className=" text-red-500">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-4 h-4"
                          >
                            <path
                              fillRule="evenodd"
                              d="M19.902 4.098a3.75 3.75 0 00-5.304 0l-4.5 4.5a3.75 3.75 0 001.035 6.037.75.75 0 01-.646 1.353 5.25 5.25 0 01-1.449-8.45l4.5-4.5a5.25 5.25 0 117.424 7.424l-1.757 1.757a.75.75 0 11-1.06-1.06l1.757-1.757a3.75 3.75 0 000-5.304zm-7.389 4.267a.75.75 0 011-.353 5.25 5.25 0 011.449 8.45l-4.5 4.5a5.25 5.25 0 11-7.424-7.424l1.757-1.757a.75.75 0 111.06 1.06l-1.757 1.757a3.75 3.75 0 105.304 5.304l4.5-4.5a3.75 3.75 0 00-1.035-6.037.75.75 0 01-.354-1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                        <div className="">
                          <span className="text-gray-500 mx-2">Link :</span>
                          {portfolioModalData?.link ? (
                            <a
                              href={portfolioModalData?.link}
                              target="_blank"
                              rel="noreferrer"
                              className="hover:text-red-500 duration-300"
                            >
                              {portfolioModalData?.link}
                            </a>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>

                      <div className="flex items-center">
                        <div className=" text-red-500">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-4 h-4"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0016.5 9h-1.875a1.875 1.875 0 01-1.875-1.875V5.25A3.75 3.75 0 009 1.5H5.625zM7.5 15a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 017.5 15zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H8.25z"
                              clipRule="evenodd"
                            />
                            <path d="M12.971 1.816A5.23 5.23 0 0114.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 013.434 1.279 9.768 9.768 0 00-6.963-6.963z" />
                          </svg>
                        </div>
                        <div className="">
                          <span className="text-gray-500 mx-2">
                            Description :
                          </span>
                        </div>
                      </div>
                      <p className="mx-6 whitespace-pre-line">
                        {portfolioModalData?.description}
                      </p>
                    </div>
                  </Tabs.Item>
                </Tabs.Group>
                {/* {portfolioModalData?.id} */}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* <!-- end Large Modal --> */}
    </>
  );
}

export default SitePortfolio;
