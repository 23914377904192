import { useQuery } from "@tanstack/react-query";
import Axios from "axios";
import { environment } from "../environment";

export const useGetBasicInformation = () => {
  // Queries
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["get-basic-information"],
    queryFn: () => {
      return Axios.get(environment.api + "basic-information").then(
        (res) => res.data
      );
    },
  });

  return { data, isLoading, refetch };
};
