import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import App from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SiteMaster from "./Layouts/Master";
import SiteIndex from "./Pages/Index/Index";
import SiteContact from "./Pages/Contact/Contact";
import SiteAbout from "./Pages/About/About";
import SitePortfolio from "./Pages/Portfolio/Portfolio";
import SiteNews from "./Pages/News/News";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// Create a client
const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SiteMaster />}>
            <Route index element={<SiteIndex />}></Route>
            <Route path="/about" element={<SiteAbout />}></Route>
            <Route path="/portfolio" element={<SitePortfolio />}></Route>
            <Route path="/news" element={<SiteNews />}></Route>
            <Route path="/contact" element={<SiteContact />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);
