import { createContext, useEffect } from "react";
import useLocalStorage from "../Hooks/useLocalStorage";
import { useGetBasicInformation } from "../api/useGetBasicInformation";

export const BasicInformationContext = createContext(null);

export const BasicInformationProvider = ({ children }) => {
  const [basic_information, setBasicInformation] = useLocalStorage(
    "basic-information",
    []
  );

  const { data, isLoading, refetch } = useGetBasicInformation();

  useEffect(() => {
    if (data) {
      setBasicInformation(data);
      console.log(data);
    }
  }, [data]);

  const value = {
    basic_information,
    isLoading,
    refetch,
  };

  return (
    <BasicInformationContext.Provider value={value}>
      {children}
    </BasicInformationContext.Provider>
  );
};
