import { motion } from "framer-motion";
// import Swiper core and required modules
import { Autoplay, FreeMode } from "swiper";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";

function SiteNews() {
  return (
    <motion.main
      initial={{ y: "5%", opacity: 0 }}
      animate={{ y: "0", opacity: 1 }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      exit={{ opacity: 1 }}
      className="w-full h-5/6 pt-20 pb-10 no-scrollbar overflow-y-auto relative z-10"
    >
      {/* <!-- content --> */}
      <section className="w-full h-full">
        <div className="lg:px-72 px-5 text-white pt-10">
          <h1 className="font-bold text-3xl">
            Latest <span className="text-red-500">News</span>
          </h1>
          <div className="border-dashed border-b w-full border-zinc-800 mt-5"></div>
          <div className="mt-5">
            {/* <!-- Swiper --> */}
            <Swiper
              spaceBetween={30}
              slidesPerView={3}
              freeMode={true}
              modules={[FreeMode, Autoplay]}
              className="mySwiper  "
              breakpoints={{
                // when window width is >= 320px
                200: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                // when window width is >= 640px
                640: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },

                1280: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: true,
              }}
            >
              <SwiperSlide>
                <div className="swiper-slide h-72 bg-zinc-800 rounded-xl overflow-hidden cursor-pointer">
                  <div className=" w-full h-full relative  overflow-hidden">
                    <div className="w-full h-40">
                      <img
                        src="https://flowbite.com/docs/images/blog/image-3.jpg"
                        className="object-cover w-full h-full group-hover:scale-110 duration-300"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div className="h-32 p-5">
                      <h5 className="text-lg font-bold text-white hover:text-red-500 duration-300">
                        Noteworthy technology acquisitions 2021
                      </h5>
                      <p className="font-normal text-gray-400">Technology</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide h-72 bg-zinc-800 rounded-xl overflow-hidden cursor-pointer">
                  <div className=" w-full h-full relative  overflow-hidden">
                    <div className="w-full h-40">
                      <img
                        src="https://flowbite.com/docs/images/blog/image-4.jpg"
                        className="object-cover w-full h-full group-hover:scale-110 duration-300"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div className="h-32 p-5">
                      <h5 className="text-lg font-bold text-white hover:text-red-500 duration-300">
                        Noteworthy technology acquisitions 2021
                      </h5>
                      <p className="font-normal text-gray-400">Technology</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide h-72 bg-zinc-800 rounded-xl overflow-hidden cursor-pointer">
                  <div className=" w-full h-full relative  overflow-hidden">
                    <div className="w-full h-40">
                      <img
                        src="https://flowbite.com/docs/images/blog/image-1.jpg"
                        className="object-cover w-full h-full group-hover:scale-110 duration-300"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div className="h-32 p-5">
                      <h5 className="text-lg font-bold text-white hover:text-red-500 duration-300">
                        Noteworthy technology acquisitions 2021
                      </h5>
                      <p className="font-normal text-gray-400">Technology</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide h-72 bg-zinc-800 rounded-xl overflow-hidden cursor-pointer">
                  <div className=" w-full h-full relative  overflow-hidden">
                    <div className="w-full h-40">
                      <img
                        src="https://flowbite.com/docs/images/blog/image-2.jpg"
                        className="object-cover w-full h-full group-hover:scale-110 duration-300"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div className="h-32 p-5">
                      <h5 className="text-lg font-bold text-white hover:text-red-500 duration-300">
                        Noteworthy technology acquisitions 2021
                      </h5>
                      <p className="font-normal text-gray-400">Technology</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>
      {/* <!-- end content --> */}
    </motion.main>
  );
}

export default SiteNews;
