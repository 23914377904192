import { motion } from "framer-motion";

import { useState, useContext } from "react";
import { Modal } from "flowbite-react";
import { BasicInformationContext } from "../../Context/BasicInformationContext";
import { environment } from "../../environment";
import { AllDataContext } from "../../Context/AllDataContext";

function SiteAbout() {
  const { basic_information } = useContext(BasicInformationContext);
  const { education, skill, workplace } = useContext(AllDataContext);
  const [isShow, setIsShow] = useState(false);
  const toggleModal = () => {
    setIsShow((prevVal) => !prevVal);
  };
  return (
    <>
      {/* <!-- main content --> */}

      <motion.main
        initial={{ y: "5%", opacity: 0 }}
        animate={{ y: "0", opacity: 1 }}
        transition={{ duration: 0.75, ease: "easeOut" }}
        exit={{ opacity: 1 }}
        className="w-full h-5/6 pt-20 pb-10 no-scrollbar overflow-y-auto relative z-10"
      >
        {/* <!-- content --> */}
        <section className="w-full h-full">
          <div className="lg:px-72 px-5 text-white pt-10">
            <div className="w-full flex lg:flex-row flex-col lg:justify-between">
              <div className="lg:w-1/2 w-full h-full flex justify-center">
                <div className="lg:w-5/6 w-60 h-60 lg:h-full rounded-full lg:rounded-xl overflow-hidden">
                  <img
                    src={environment.storage + basic_information.personal_image}
                    alt="safi.jpg"
                  />
                </div>
              </div>
              <div className="lg:w-1/2 w-full h-full">
                <div className="w-full h-full flex flex-col lg:items-start items-center space-y-5 text-white">
                  <h1 className="text-5xl uppercase font-bold">
                    safi <span className="text-red-500">ibrahim</span>
                  </h1>
                  <p className="text-xl font-semibold">
                    <span className="text-gray-400">Creative</span> Developer
                  </p>
                  <div className="border-dashed border-b w-full border-zinc-800 mt-5"></div>
                  <p>{basic_information.about_me}</p>
                  <div
                    onClick={toggleModal}
                    className="w-fit py-2 px-10 bg-red-500 rounded-full select-none cursor-pointer font-semibold text-white hover:bg-white hover:text-black duration-300"
                  >
                    Learn More
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end content --> */}
      </motion.main>
      {/* <!-- end main content --> */}

      {/* <!-- Large Modal --> */}

      <Modal
        dismissible="true"
        show={isShow}
        size="4xl"
        position="center"
        className=""
        onClose={toggleModal}
      >
        <Modal.Body className="bg-zinc-800 ">
          <div className="relative w-full h-full max-w-4xl ">
            {/* <!-- Modal content --> */}
            <div className="relative">
              <div className="absolute lg:-right-20 right-0 top-0 z-50">
                <button
                  type="button"
                  onClick={toggleModal}
                  className="text-gray-400 border bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="large-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* <!-- Modal body --> */}
              <div className=" space-y-6 relative text-white">
                <div className="flex md:flex-row flex-col space-y-5 md:space-y-0">
                  <div className="h-full md:w-1/3 md:border-dashed md:border-zinc-600 md:border-r flex flex-col items-center">
                    <div className="w-28 h-28 rounded-full overflow-hidden">
                      <img
                        src={
                          environment.storage + basic_information.personal_image
                        }
                        className="object-cover"
                        alt="safi.jpg"
                      />
                    </div>
                    <h1 className="text-2xl uppercase font-bold mt-5">
                      safi <span className="text-red-500">ibrahim</span>
                    </h1>
                    <p className="text-base font-semibold">
                      <span className="text-gray-400">Creative</span> Developer
                    </p>
                    <div className="pr-5 py-5 w-full">
                      <div className="flex w-full py-2 border-b border-dashed border-zinc-600">
                        <div className="w-9 text-red-500">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                        <div className="w-fit">Safi Ibrahim</div>
                      </div>
                      <div className="flex w-full py-2 border-b border-dashed border-zinc-600">
                        <div className="w-9 text-red-500">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-5 h-5"
                          >
                            <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                            <path
                              fillRule="evenodd"
                              d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                        <div className="w-fit">01.01.1996</div>
                      </div>
                      <div className="flex w-full py-2 border-b border-dashed border-zinc-600">
                        <div className="w-9 text-red-500">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              fillRule="evenodd"
                              d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                        <div className="w-fit">{basic_information.address}</div>
                      </div>
                      <div className="flex w-full py-2 border-b border-dashed border-zinc-600">
                        <div className="w-9 text-red-500">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              fillRule="evenodd"
                              d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                        <div className="w-fit">+{basic_information.phone}</div>
                      </div>
                    </div>
                    <a
                      href={environment.storage + basic_information.cv_file}
                      target="_blank"
                      rel="noreferrer"
                      className="w-fit py-2 px-10 bg-red-500 rounded-full select-none cursor-pointer font-semibold text-white hover:bg-white hover:text-black duration-300"
                    >
                      Download CV
                    </a>
                  </div>

                  <div className="md:h-96 md:w-2/3 md:px-7 flex-col flex space-y-12 md:no-scrollbar md:overflow-y-auto">
                    {/* <!-- About Me --> */}
                    <div className="">
                      <h1 className="text-xl font-semibold">
                        About <span className="text-red-500">Me</span>
                      </h1>
                      <div className="border-dashed border-b w-full border-zinc-600 mt-3"></div>
                      <p className="mt-3">{basic_information.about_me}</p>
                    </div>
                    {/* <!-- end About Me --> */}

                    {/* <!-- Working Timeline --> */}
                    <div className="">
                      <h1 className="text-xl font-semibold">
                        Working <span className="text-red-500">Timeline</span>
                      </h1>
                      <div className="border-dashed border-b w-full border-zinc-600 mt-3"></div>

                      <ol className="relative text-gray-500 border-l border-gray-200 ml-6 mt-5">
                        {workplace.map((item) => {
                          return (
                            <li key={item.id} className="mb-10 ml-6">
                              <span className="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  className="w-5 h-5"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M7.5 5.25a3 3 0 013-3h3a3 3 0 013 3v.205c.933.085 1.857.197 2.774.334 1.454.218 2.476 1.483 2.476 2.917v3.033c0 1.211-.734 2.352-1.936 2.752A24.726 24.726 0 0112 15.75c-2.73 0-5.357-.442-7.814-1.259-1.202-.4-1.936-1.541-1.936-2.752V8.706c0-1.434 1.022-2.7 2.476-2.917A48.814 48.814 0 017.5 5.455V5.25zm7.5 0v.09a49.488 49.488 0 00-6 0v-.09a1.5 1.5 0 011.5-1.5h3a1.5 1.5 0 011.5 1.5zm-3 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                                    clipRule="evenodd"
                                  />
                                  <path d="M3 18.4v-2.796a4.3 4.3 0 00.713.31A26.226 26.226 0 0012 17.25c2.892 0 5.68-.468 8.287-1.335.252-.084.49-.189.713-.311V18.4c0 1.452-1.047 2.728-2.523 2.923-2.12.282-4.282.427-6.477.427a49.19 49.19 0 01-6.477-.427C4.047 21.128 3 19.852 3 18.4z" />
                                </svg>
                              </span>
                              <div className="flex justify-between items-center">
                                <div>
                                  <h3 className="font-medium leading-tight">
                                    {item.name_of_company}
                                  </h3>
                                  <p className="text-sm"> {item.position}</p>
                                </div>
                                <div>
                                  <p className="float-right w-fit">
                                    {item.date}
                                  </p>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ol>
                    </div>
                    {/* <!-- end Working Timeline --> */}

                    {/* <!-- Education Timeline --> */}
                    <div className="">
                      <h1 className="text-xl font-semibold">
                        Education <span className="text-red-500">Timeline</span>
                      </h1>
                      <div className="border-dashed border-b w-full border-zinc-600 mt-3"></div>

                      <ol className="relative text-gray-500 border-l border-gray-200 ml-6 mt-5">
                        {education.map((item) => {
                          return (
                            <li key={item.id} className="mb-10 ml-6">
                              <span className="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  className="w-5 h-5"
                                >
                                  <path d="M11.7 2.805a.75.75 0 01.6 0A60.65 60.65 0 0122.83 8.72a.75.75 0 01-.231 1.337 49.949 49.949 0 00-9.902 3.912l-.003.002-.34.18a.75.75 0 01-.707 0A50.009 50.009 0 007.5 12.174v-.224c0-.131.067-.248.172-.311a54.614 54.614 0 014.653-2.52.75.75 0 00-.65-1.352 56.129 56.129 0 00-4.78 2.589 1.858 1.858 0 00-.859 1.228 49.803 49.803 0 00-4.634-1.527.75.75 0 01-.231-1.337A60.653 60.653 0 0111.7 2.805z" />
                                  <path d="M13.06 15.473a48.45 48.45 0 017.666-3.282c.134 1.414.22 2.843.255 4.285a.75.75 0 01-.46.71 47.878 47.878 0 00-8.105 4.342.75.75 0 01-.832 0 47.877 47.877 0 00-8.104-4.342.75.75 0 01-.461-.71c.035-1.442.121-2.87.255-4.286A48.4 48.4 0 016 13.18v1.27a1.5 1.5 0 00-.14 2.508c-.09.38-.222.753-.397 1.11.452.213.901.434 1.346.661a6.729 6.729 0 00.551-1.608 1.5 1.5 0 00.14-2.67v-.645a48.549 48.549 0 013.44 1.668 2.25 2.25 0 002.12 0z" />
                                  <path d="M4.462 19.462c.42-.419.753-.89 1-1.394.453.213.902.434 1.347.661a6.743 6.743 0 01-1.286 1.794.75.75 0 11-1.06-1.06z" />
                                </svg>
                              </span>
                              <div className="flex justify-between items-center">
                                <div>
                                  <h3 className="font-medium leading-tight">
                                    {item.name_of_university}
                                  </h3>
                                  <p className="text-sm">{item.degree}</p>
                                </div>
                                <div>
                                  <p className="float-right w-fit">
                                    {item.date}
                                  </p>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ol>
                    </div>
                    {/* <!-- end Education Timeline --> */}

                    {/* <!-- Quality Services--> */}
                    <div className="">
                      <h1 className="text-xl font-semibold">
                        Quality <span className="text-red-500">Services</span>
                      </h1>
                      <div className="border-dashed border-b w-full border-zinc-600 mt-3"></div>
                      <ul className="mt-3">
                        {skill.map((item) => {
                          return (
                            <li
                              key={item.id}
                              className="flex items-center space-x-3"
                            >
                              {/* <!-- Icon --> */}
                              <div className="text-red-500">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  className="w-4 h-4"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </div>

                              <span>{item.skill}</span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    {/* <!-- end Quality Services --> */}

                    {/* <!-- Programming Skills --> */}
                    {/* <div className="">
                      <h1 className="text-xl font-semibold">
                        Programming <span className="text-red-500">Skills</span>
                      </h1>
                      <div className="border-dashed border-b w-full border-zinc-600 mt-3"></div>
                      <p className="mt-3">
                        Hello everybody! My name is Safi Ibrahim. Lorem ipsum
                        dolor sit amet consectetur adipisicing elit. Voluptatum
                        qui, voluptates quia dolore optio voluptatem a accusamus
                        adipisci omnis porro, laborum facilis aperiam
                        repudiandae iste temporibus numquam esse perferendis
                        harum.
                      </p>
                    </div> */}
                    {/* <!-- end Programming Skills --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* <!-- end Large Modal --> */}
    </>
  );
}

export default SiteAbout;
