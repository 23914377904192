import { useMutation } from "@tanstack/react-query";
import Axios from "axios";
import { environment } from "../environment";

export const usePostSendMessage = (
  dataForm,
  setErrors,
  setIsSuccess,
  refetch = () => {}
) => {
  const mutation = useMutation({
    mutationFn: () => {
      return Axios.post(environment.api + "messages", dataForm).then(
        (res) => res.data
      );
    },
    onSuccess: (data) => {
      console.log(data);
      refetch();
      setIsSuccess(true);
    },
    onError: (error) => {
      console.log("error");
      if (error.response.status === 429) {
        setErrors({ general_message: [error.response.data.message] });
      } else {
        setErrors(error.response.data.errors);
      }
    },
  });

  return { mutation };
};
