import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";

function SiteIndex() {
  return (
    <motion.main
      initial={{ y: "5%", opacity: 0 }}
      animate={{ y: "0", opacity: 1 }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      exit={{ opacity: 1 }}
      className="w-full h-5/6 pt-20 pb-10 no-scrollbar overflow-y-auto relative z-10"
    >
      {/* <!-- content --> */}
      <section className="w-full h-full">
        <div className="w-full h-full px-5 lg:px-40 flex flex-col justify-center items-center space-y-5 text-center text-white">
          <h1 className="lg:text-7xl md:text-5xl text-4xl uppercase font-bold">
            safi <span className="text-red-500">ibrahim</span>
          </h1>
          <p className="lg:text-3xl md:text-2xl text-xl font-semibold">
            <span className="text-gray-400">Creative</span> Developer
          </p>
          <NavLink
            to="/contact"
            className="w-fit py-2 px-10 bg-red-500 rounded-full select-none cursor-pointer font-semibold text-white hover:bg-white hover:text-black duration-300"
          >
            Get in Touch
          </NavLink>
        </div>
      </section>
      {/* <!-- end content --> */}
    </motion.main>
  );
}

export default SiteIndex;
