import { Toast } from "flowbite-react";
import { motion } from "framer-motion";
import { useContext, useState } from "react";
import { usePostSendMessage } from "../../api/usePostSendMessage";
import { useErrorMessage } from "../../Components/useErrorMessage";
import { BasicInformationContext } from "../../Context/BasicInformationContext";

function SiteContact() {
  const { basic_information } = useContext(BasicInformationContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const dataForm = {
    name: name,
    email: email,
    message: message,
  };

  // Mutations
  const { mutation } = usePostSendMessage(dataForm, setErrors, setIsSuccess);

  function handelSubmit(event) {
    event.preventDefault();
    setIsSuccess(false);
    if (mutation.isLoading) return;
    setErrors({});
    // console.log("sending");
    mutation.mutate();
  }

  return (
    <motion.main
      initial={{ y: "5%", opacity: 0 }}
      animate={{ y: "0", opacity: 1 }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      exit={{ opacity: 1 }}
      className="w-full h-5/6 pt-20 pb-10 no-scrollbar overflow-y-auto relative z-10"
    >
      {/* <!-- content --> */}
      <section className="w-full h-full">
        <div className="lg:px-72 px-5 text-white pt-10">
          <h1 className="font-bold text-3xl">
            Get in <span className="text-red-500">Touch</span>
          </h1>
          <div className="border-dashed border-b w-full border-zinc-800 mt-5"></div>
          <div className="flex lg:flex-row space-y-5 lg:space-y-0 flex-col w-full mt-10">
            <div className="flex flex-col space-y-5 lg:w-1/2 w-full">
              <div className="py-3 rounded-2xl lg:w-5/6 w-full bg-zinc-800 grid grid-cols-6 items-center">
                <div className="flex justify-center items-center text-red-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      fillRule="evenodd"
                      d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="col-span-5">
                  <div className="hover:text-red-500 duration-500">
                    {basic_information.address}
                  </div>
                </div>
              </div>
              <div className="py-3 rounded-2xl lg:w-5/6 w-full bg-zinc-800 grid grid-cols-6 items-center">
                <div className="flex justify-center items-center text-red-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="col-span-5">
                  <a
                    href={"tel:" + basic_information.phone}
                    className="hover:text-red-500 duration-500"
                  >
                    +{basic_information.phone}
                  </a>
                </div>
              </div>
              <div className="py-3 rounded-2xl lg:w-5/6 w-full bg-zinc-800 grid grid-cols-6 items-center">
                <div className="flex justify-center items-center text-red-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-4 h-4"
                  >
                    <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                    <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                  </svg>
                </div>
                <div className="col-span-5">
                  <a
                    href={"mailto:" + basic_information.email}
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-red-500 duration-500"
                  >
                    {basic_information.email}
                  </a>
                </div>
              </div>
              <div className="py-3 rounded-2xl lg:w-5/6 w-full bg-zinc-800 grid grid-cols-6 items-center">
                <div className="flex justify-center items-center text-red-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-4 h-4"
                  >
                    <path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 00-1.032-.211 50.89 50.89 0 00-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 002.433 3.984L7.28 21.53A.75.75 0 016 21v-4.03a48.527 48.527 0 01-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979z" />
                    <path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 001.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0015.75 7.5z" />
                  </svg>
                </div>
                <div className="col-span-5">
                  <a
                    href="https://t.me/safi_ibrahim"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-red-500 duration-500"
                  >
                    @safi_ibrahim
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-5 lg:w-1/2 w-full">
              <div className="flex xl:flex-row flex-col xl:space-x-5 space-y-5 xl:space-y-0">
                <div className="py-3 px-5 rounded-2xl xl:w-1/2 w-full bg-zinc-800">
                  <input
                    type="text"
                    name="name"
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                    id="name"
                    placeholder="Name"
                    className="bg-transparent outline-none border-none w-full "
                  />
                  {useErrorMessage(errors, "name")}
                </div>

                <div className="py-3 px-5 rounded-2xl xl:w-1/2 w-full bg-zinc-800">
                  <input
                    type="text"
                    name="Email"
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    id="name"
                    placeholder="Email"
                    className="bg-transparent outline-none border-none w-full "
                  />
                  {useErrorMessage(errors, "email")}
                </div>
              </div>

              <div className="py-3 px-5 rounded-2xl w-full bg-zinc-800">
                <textarea
                  name="message"
                  id="message"
                  onChange={(event) => {
                    setMessage(event.target.value);
                  }}
                  className="bg-transparent outline-none border-none w-full h-24 resize-none"
                  placeholder="Message"
                ></textarea>
                {useErrorMessage(errors, "message")}
              </div>

              <div
                className={
                  isSuccess
                    ? "space-x-4 divide-x divide-gray-200 dark:divide-gray-700 "
                    : "space-x-4 divide-x divide-gray-200 dark:divide-gray-700 hidden"
                }
              >
                <Toast>
                  <div className="text-green-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>

                  <div className="pl-4 text-sm font-normal">
                    Message sent successfully.
                  </div>
                  <Toast.Toggle />
                </Toast>
              </div>

              {useErrorMessage(errors, "general_message")}

              <div
                onClick={handelSubmit}
                className="w-fit py-2 px-10 bg-red-500 rounded-full select-none cursor-pointer font-semibold text-white hover:bg-white hover:text-black duration-300"
              >
                {mutation.isLoading ? "Sending..." : "Send Message"}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end content --> */}
    </motion.main>
  );
}

export default SiteContact;
