import { createContext, useEffect } from "react";
import { useGetAllData } from "../api/useGetAllData";
import useLocalStorage from "../Hooks/useLocalStorage";

export const AllDataContext = createContext(null);

export const AllDataProvider = ({ children }) => {
  const [education, setEducation] = useLocalStorage("education", []);
  const [portfolio, setPortfolio] = useLocalStorage("portfolio", []);
  const [skill, setSkill] = useLocalStorage("skill", []);
  const [workplace, setWorkplace] = useLocalStorage("workplace", []);

  const { data, isLoading, refetch } = useGetAllData();

  useEffect(() => {
    if (data) {
      setEducation(data.data.education);
      setPortfolio(data.data.portfolio);
      setSkill(data.data.skill);
      setWorkplace(data.data.workplace);

      console.log(data.data);
    }
  }, [data]);

  const value = {
    education,
    portfolio,
    skill,
    workplace,
    isLoading,
    refetch,
  };

  return (
    <AllDataContext.Provider value={value}>{children}</AllDataContext.Provider>
  );
};
